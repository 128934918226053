.header {
	.header-socket {
		background: url("/images/Header_bg.png") no-repeat bottom/cover !important;
		min-height: 135px;
		transition: height 0.25s ease;

		@include media-breakpoint-down(sm) {
			min-height: 120px;
		}

		.container-two-columns {
			@include media-breakpoint-down(lg) {
				padding: 0 15px !important;
			}

			.container-holder {
				position: relative;

				@include media-breakpoint-down(lg) {
					flex-direction: column;
				}

				.column {
					@include media-breakpoint-down(lg) {
						padding: 0 !important;
					}
				}

				.column.one {
					z-index: 11;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 50px;

					@include media-breakpoint-up(sm) {
						margin: 0 0 20px 0
					}

					@media screen and (max-width: 320px) {
						max-width: 230px !important;
					}
					@media screen and (max-width: 375px) {
						max-width: 250px;
					}

					.page_block.logo {
						position: relative;

						@media screen and (max-width: 375px) {
							img {
								width: 150px;
							}
						}

						&::after {
							content: "";
							background: url('/images/camper.png') no-repeat;
							background-size: contain;
							width: 126px;
							height: 60px;
							position: absolute;
							top: 25px;
							left: auto;
							margin-left: 12px;
							-moz-transform: scaleX(-1);
							-o-transform: scaleX(-1);
							-webkit-transform: scaleX(-1);
							transform: scaleX(-1);

							@include media-breakpoint-down(sm) {
								content: none;
							}
						}

						@include media-breakpoint-down(lg) {
							order: 2;
							margin-top: 20px;
						}
					}

					.navbar-toggler {
						color: $base-2;
						padding: 0.25rem 0.5rem;

						&[aria-expanded="true"] {
							.fa-bars {
								&::before {
									content: "\f00d";
								}
							}
						}

						@include media-breakpoint-down(lg) {
							order: 1;
							margin-top: 15px;
						}
						@include media-breakpoint-up(xl) {
							display: none;
						}
					}
				}

				.column.two {
					position: relative;

					@include media-breakpoint-up(xl) {
						align-items: center;
						margin-top: 10px;
					}

					.navbar-collapse {
						@include media-breakpoint-up(xl) {
							display: flex !important;
							flex-basis: auto;
							order: 1;
						}

						.navbar-nav {
							width: 100%;
							background: $highlight;

							@include media-breakpoint-up(xl) {
								background: transparent;
							}

							.nav-item {
								font-family: $font-maven-pro;
								font-weight: 700;
								margin: 2px 20px;

								@include media-breakpoint-up(xl) {
									margin: 0 25px 0 0;
								}

								.nav-link {
									font-size: $font-size-base / 1.0667;
									color: $white;
									text-transform: uppercase;

									@include media-breakpoint-up(xl) {
										color: $highlight;
									}

									&:hover,
									&:active {
										color: $primary;
									}
								}

								.dropdown-toggle {
									position: relative;

									&::after {
										content: "\f078";
										position: absolute;
										right: -12px;
										display: block;
										font-family: $font-awesome;
										font-weight: 700;
										transform: translateY(-50%);
										top: 50%;
										font-size: $font-size-base_xs;
										border: none !important;
									}

									@include media-breakpoint-down(lg) {
										&[aria-expanded="true"] {
											&::after {
												content: "\f068";
											}
										}
										&::after {
											margin-right: 3px;
											content: "\F067";
											font-family: $font-awesome;
											font-size: $font-size-base_xs;
											font-weight: 700;
											border: none;
										}
									}
								}

								.dropdown-menu {
									left: -50%;
									border: 1px solid $base-1;
									border-radius: 6px;
									box-shadow: 0px 3px 6px rgba($black, 0.16);

									.nav-item {
										margin: 0;

										@include media-breakpoint-up(xl) {
											margin-right: 0;
										}

										.nav-link {
											padding: 8px 15px;
											font-size: $font-size-base_sm;
											text-transform: capitalize;
										}
									}

									@include media-breakpoint-up(xl) {
										&:before,
										&:after {
											content: "";
											display: block;
											height: 0;
											left: 50%;
											position: absolute;
											width: 0;
										}
										&:before {
											border-left: 8px solid transparent;
											border-right: 8px solid transparent;
											border-bottom: 6px solid $base-1;
											margin-left: -5px;
											top: -6px;
											z-index: 4;
										}
										&:after {
											border-left: 6px solid transparent;
											border-right: 6px solid transparent;
											border-bottom: 5px solid $white;
											margin-left: -3px;
											top: -5px;
											z-index: 5;
										}
									}
									@include media-breakpoint-down(lg) {
										position: static !important;
										transform: none !important;
										min-width: 100%;
										background: $highlight;
										border: none;
										box-shadow: none;
										padding: 0;
										margin: 0;
									}
								}
							}

							@include media-breakpoint-up(xl) {
								flex-direction: row;
								flex-grow: 1;
								justify-content: flex-end;
							}
						}
					}

					.page_block.vocabulary_term_list {
						@include media-breakpoint-down(lg) {
							position: absolute;
							top: -25px;
							right: 0;
						}
						@include media-breakpoint-up(xl) {
							order: 2;
						}

						li {
							a {
								width: 94px;
								height: 94px;
								border-radius: 100%;
								background: $highlight;
								font-size: $font-size-base * 1.125;
								font-family: $font-montserrat;
								text-align: center;
								display: flex;
								align-items: center;
								color: $white;
								text-decoration: none;

								@include media-breakpoint-down(lg) {
									box-shadow: 3px 3px 6px rgba($black, 0.16);
								}

								&:hover {
									background: $primary;
									color: $white;
									box-shadow: 0px 3px 6px rgba($black, 0.16);
								}
							}
						}
					}

					.page_block.site_switcher {
						@include media-breakpoint-down(lg) {
							display: none;
						}
						@include media-breakpoint-up(xl) {
							order: 4;
						}

						.navbar-nav {
							flex-direction: row;
							width: auto;

							.nav-item {
								width: 21px;
								height: 21px;
								border: 1px solid transparent;
								border-radius: 100%;
								overflow: hidden;

								&:not(:last-child) {
									margin-right: 6px;
								}

								&:hover {
									border: 1px solid $white;
									box-shadow: 0 3px 6px rgba($black, 0.16);
								}

								.nav-link {
									font-size: $font-size-base * 1.125;
									line-height: 1;
									padding: 0;

									img {
										max-width: 100%;
										transform: scale(1.6);
									}
								}
							}
						}
					}

					.navbar-nav.socials {
						display: none;
						flex-direction: row;
						width: auto;

						@include media-breakpoint-down(lg) {
							position: absolute;
							margin-top: 10px;
							top: -105px;
							right: 0;
						}
						@include media-breakpoint-up(xl) {
							order: 3;
						}

						.nav-item {
							margin-right: 6px;

							.nav-link {
								font-size: $font-size-base * 1.125;
								color: $highlight;

								&:hover {
									color: $primary;
								}

								@include media-breakpoint-up(xl) {
									font-size: $font-size-base_sm;
								}

								i {
									border: 1px solid;
									padding: 4px 5px;
									border-radius: 100%;
								}
							}
						}
					}
				}
			}
		}
	}

	&.sticky {
		box-shadow: none;
	}
}


.header-socket .vocabulary_term_list {
	display: none !important;
}

// .mini-sab .page_block.link {
// 	visibility: hidden;
// }
