.eyecatcher {
	@include media-breakpoint-down(lg) {
		margin-top: -15px;
	}
	&.large {
		.container-default { max-width: none;}
	}

	.owl-carousel {
		z-index: 2;

		&.large {
			.item {
				padding: 36vh 0;
				max-width: 100%;

				.owl-container {
					display: flex;
					justify-content: center;
					position: absolute;
					bottom: 85px;
					max-width: 570px;
					margin: auto;
					left: 0;
					right: 0;

					@include media-breakpoint-down(sm) {
						bottom: 85px;
						width: 200px;
						margin: auto;
						left: 5%;
						right: auto;
					}
					.owl-caption {
						.owl-title {
							text-align: center;

							@include media-breakpoint-down(sm) {
								font-size: $font-size-base * 2.25;
							}
						}
					}
				}
			}
		}
		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}
.spacer.large,
.eyecatcher {
	.item {
		max-width: 100% !important;

		.owl-container {
			display: flex;
			justify-content: center;

			.owl-caption {
				.owl-title {
					text-align: center;

					@include media-breakpoint-down(sm) {
						font-size: $font-size-base * 2.25;
					}
				}
			}
		}
	}
}
