.btn {
    display: inline-flex;
    align-items: center;
    padding: 5px 20px;
    font-size: $h5-font-size !important;
    font-family: $font-maven-pro;
    font-weight: 700;
    border-radius: 17px;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;
    box-shadow: 0px 3px 6px rgba($black, 0.16);

    &-primary {
        @extend .btn;
        color: $white;
        background: $highlight;

        &:hover,
        &:active {
            color: $white;
            background: $primary;
            border: $border-width solid $primary;
        }
    }
    &-outline-primary {
        @extend .btn;
        color: $highlight;
        background: $white;
        border: $border-width solid $highlight;
       
        &:hover,
        &:active {
            color: $white;
            background: $highlight;
         }
    }
    &-secondary {
        @extend .btn;
        color: $primary;
        background: $white;
        border-color: $primary;

        &:hover,
        &:active {
            color: $white;
            background: $primary;
            border: $border-width solid $primary;
        }
    }
    &-outline-secondary {
        @extend .btn;
        color: $secondary;
        border-color: $secondary;
       
        &:hover,
        &:active {
            color: $white;
            background: $secondary;
        }
    }
}
.card {
    &,
    &.card-overlay {
        .card-btn {
            color: $white;
            background: $highlight;
            border: $border-width solid $highlight;
            &:focus {
                box-shadow: none;
                outline: none;
            }
            &:hover {
                color: $highlight;
                background: $white;
                border: $border-width solid $highlight;
            }
        }
    }    
}
