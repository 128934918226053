.mini-sab {
  padding: $mini-sab-padding;
  background-color: $mini-sab-background;

  .container {
		.container-holder {
			@include media-breakpoint-down(sm) {
				flex: 1;
			}
		}
	}

	.btn-toggle-mini-sab {
		@extend .btn;
		@extend .btn-primary;
		@extend .w-100;
		border-radius: 4px !important;
	}
}