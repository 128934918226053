/* bootstrap > theme-colors
   ========================================================================== */
   $primary: #EB6800 !default;
   $secondary: #99A600 !default;
   $tertiary: #353720 !default;
   $highlight: #004F9F;
   $base: #000000;
   $body-color: $base !default;
   
   /* bootstrap > font
       ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&family=Sue+Ellen+Francisco&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap');

   $font-sue-ellen:  'Sue Ellen Francisco', cursive;
   $font-maven-pro: 'Maven Pro', sans-serif;
   $font-montserrat: 'Montserrat', sans-serif;
   $font-open-sans: 'Open Sans', sans-serif;
   $font-awesome: "Font Awesome 5 Pro";
   
   $font-family-base: $font-maven-pro !default;
   
   $font-weight-regular: 400;
   $font-weight-base: 500;
   
   $font-size-base: 1rem !default; //16px
   $font-size-base_sm: 0.875rem !default; //14px
   $font-size-base_xs: 0.813rem !default; //13px
   $font-size-base_xxs: 0.75rem !default; //12px
   
   $h1-font-size: $font-size-base * 3 !default;
   $h2-font-size: $font-size-base * 3 !default;
   $h3-font-size: $font-size-base * 2.25 !default;
   $h4-font-size: $font-size-base * 1.1875!default;
   $h5-font-size: $font-size-base * 0.9375 !default;
   $h6-font-size: $font-size-base_xs !default;
   
   /* bootstrap > typography
      ========================================================================== */
   $headings-font-family: $font-sue-ellen !default;
   $headings-font-weight: 400;
   
   body.default {
      overflow-x: hidden;
   }
   
 