.intro.content {
	padding: 45px 0 70px;

	@include media-breakpoint-up(sm) {
		padding: 90px 0;
	}
	position: relative;

	&::after {
		content: "";
		background: url("/images/footer-Path695@2x.png") no-repeat;
		background-size: cover;
		width: 100%;
		height: 15px;
		position: absolute;
		bottom: -2px;
		left: 0;
		z-index: 1;
	}

	.container-default {
		.container-holder {
			max-width: 730px;
			margin: auto;
			text-align: center;

			h1 {
				color: $primary;
				text-transform: uppercase;
			}

			p {
				font-size: $font-size-base * 1.125;
			}
		}
	}
}

.home-blocks {
	background: $secondary;
	padding: 35px 0 75px;
	position: relative;

	@include media-breakpoint-up(xl) {
		padding: 80px 0 140px !important;
	}
	@media screen and (max-width: 1400px) {
		padding: 80px 0 !important;
	}

	&::before {
		background: url("/images/home-blocks-grid.svg") no-repeat center / cover;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;

		@include media-breakpoint-down(sm) {
			background: url("/images/mobile-repeat-grid.svg") repeat top / contain;
		}
	}

	&::after {
		content: "";
		background: url("/images/repeat-grid-black.svg") no-repeat bottom / cover;
		width: 100%;
		height: 15px;
		position: absolute;
		bottom: -15px;

		left: 0;
		z-index: 2;
	}

	.container-default {
		z-index: 11;
		position: relative;

		.container-holder {
			h1 {
				color: $white;
				text-align: center;
				margin-bottom: 40px;
			}
		}
	}
}

.spacer.large {
	margin-bottom: 110px;

	.container-default {
		position: relative;
		max-width: none;

		&::after {
			background: url("/images/white-grid.svg") no-repeat bottom/100%;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: -5px;
			right: 0;
			z-index: 1;

			@include media-breakpoint-up(sm) {
				bottom: -15px;
			}
		}

		.container-holder {
			.owl-carousel {
				.owl-nav {
					display: none;
				}

				.owl-dots {
					left: 0;
					bottom: -12px;

					@include media-breakpoint-up(sm) {
						left: 90%;
					}

					.owl-dot {
						&.active {
							span {
								background: $highlight;
							}
						}

						span {
							border-color: $highlight;
							box-shadow: none;
						}
					}
				}
			}
		}
	}
}

.home-double.content {
	background: $primary;
	padding: 60px 0 95px !important;
	margin-top: 120px;
	position: relative;

	@include media-breakpoint-up(sm) {
		padding: 80px 0 150px !important;
	}

	&::before {
		content: "";
		background: url("/images/repeat-grid-top.svg") no-repeat;
		background-size: cover;
		width: 100%;
		height: 40px;
		position: absolute;
		top: -35px;
		left: 0;
		z-index: 2;
	}

	&::after {
		background: url("/images/home-blocks-grid.svg") no-repeat 50%/100%;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;

		@include media-breakpoint-down(sm) {
			background: url("/images/mobile-repeat-grid.svg") repeat top/contain;
		}
	}

	.container-two-columns {
		z-index: 11;
		position: relative;

		.column.one {
			@include media-breakpoint-down(lg) {
				order: 2;
				margin-top: 30px;
			}
			@include media-breakpoint-up(sm) {
				@include make-col(12);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(5);
			}
		}

		.page_block.resource_image {
			img {
				border-radius: 100%;
			}
		}

		.column.two {
			@include media-breakpoint-down(lg) {
				order: 1;
			}
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			@include media-breakpoint-up(sm) {
				@include make-col(12);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(7);
			}

			h6,
			p,
			h1 {
				color: $white;
				order: 2;
			}

			h1 {
				font-size: $h1-font-size !important;
			}

			h6 {
				font-family: $font-maven-pro;
				font-weight: 700;
				order: 1;
			}

			p {
				order: 3;
			}

			.page_block.link {
				order: 4;
				@extend .btn-primary;
			}
		}
	}
}

.slider-section.bg-dark {
	position: relative;
	background: $base-3 !important;
	padding: 80px 0 70px;

	@include media-breakpoint-up(sm) {
		padding: 90px 0 70px;
	}

	&::before {
		content: "";
		background: url("/images/path-grid.svg") no-repeat top/100%;
		background-size: cover;
		width: 100%;
		height: 15px;
		position: absolute;
		top: -7px;
	}

	&::after {
		background: url("/images/repeat-grid-slider.svg") no-repeat bottom/100%;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: -5px;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}

	.container-default {
		.container-holder {
			h2,
			p {
				max-width: 730px;
				margin: auto;
				text-align: center;
			}

			h2 {
				font-size: $h1-font-size;
				color: $primary;
				text-transform: uppercase;
			}

			p {
				font-size: $font-size-base * 1.125;
				margin-bottom: 95px;
			}

			.btn-primary {
				display: flex;
				margin: 50px auto 0;
				width: max-content;
				z-index: 2;
				position: relative;

				@include media-breakpoint-up(sm) {
					margin: auto;
				}
			}

			.inspiration.contentblock {
				.column {
					padding: 0px 15px;

					.item,
					.item-large {
						.card {
							background: transparent;
							border: none;
							border-radius: 0;
							text-decoration: none;

							&-image {
								padding: 10px;
								box-shadow: 2px 2px 8px rgba($black, 0.4);
							}

							&-body {
								padding: 15px;
								background: transparent;

								@include media-breakpoint-up(sm) {
									padding: 15px 0;
								}

								.card-caption {
									.card-title {
										color: $primary;
										font-size: $font-size-base * 1.5;
										text-transform: uppercase;
									}

									.card-description {
										&::after {
											content: none;
										}
									}
								}
							}
						}
					}
				}

				.column.one {
					@include media-breakpoint-up(sm) {
						@include make-col(7);
					}

					.item-large {
						transform: matrix(1, 0.09, -0.09, 1, 0, 0);

						@include media-breakpoint-up(sm) {
							transform: matrix(1, 0.05, -0.05, 1, 0, 0);
						}

						.card {
							&-body {
								.card-caption {
									.card-title {
										text-align: left;
									}
								}
							}
						}
					}
				}

				.column.two {
					@include media-breakpoint-up(sm) {
						@include make-col(5);
					}

					.item {
						margin: 0;
						@include make-col(12);

						@include media-breakpoint-up(sm) {
							padding-left: 60px;
						}

						.card {
							box-shadow: none;

							&-body {
								.card-caption {
									.card-title {
										text-align: right;
									}
								}
							}
						}

						&:first-of-type {
							transform: matrix(1, -0.09, 0.09, 1, 0, 0);

							@include media-breakpoint-up(sm) {
								transform: translateY(-40px) matrix(1, -0.09, 0.09, 1, 0, 0);
							}
						}

						&:last-of-type {
							transform: matrix(1, 0.09, -0.09, 1, 0, 0);

							@include media-breakpoint-up(sm) {
								transform: translate(-20px, -40px) matrix(1, -0.05, 0.05, 1, 0, 0);
							}

							.card {
								&-body {
									.card-caption {
										.card-title {
											@include media-breakpoint-down(sm) {
												text-align: left;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.inspiration-blocks {
	padding: 75px 0 30px !important;
}

.content.overview {
	padding: 90px 0 !important;

	.info {
		position: relative;

		.btn-back {
			font-size: $font-size-base / 1.0667;
			font-weight: 700;
			position: absolute;
			top: -60px;
			padding-left: 15px;
			color: $highlight;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				color: $primary;

				&::before {
					left: -5px;
				}
			}

			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				content: "\f053" !important;
				transform: translateY(-50%);
				font-size: $font-size-base_xs;
				font-family: $font-awesome;
				font-weight: 700;
				transition: all 0.3s;
			}
		}

		h1 {
			color: $primary;
			margin-bottom: 30px;
		}

		.group__column-3 {
			div {
				@include make-col(12);
				padding-left: 0;
			}
		}
	}

	.info-extra {
		@include media-breakpoint-down(sm) {
			padding: 0 !important;
		}
	}
}

.content.detail {
	padding: 90px 0 20px;

	.info {
		position: relative;

		.btn-back {
			font-size: $font-size-base / 1.0667;
			font-weight: 700;
			position: absolute;
			top: -60px;
			padding-left: 15px;
			color: $highlight;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				color: $primary;

				&::before {
					left: -5px;
				}
			}

			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				content: "\f053" !important;
				transform: translateY(-50%);
				font-size: $font-size-base_xs;
				font-family: $font-awesome;
				font-weight: 700;
				transition: all 0.3s;
			}
		}

		h1 {
			color: $primary;
		}

		.extra {
			color: $secondary;
			font-size: $font-size-base / 1.0667;
			font-weight: 700;
			margin-bottom: 5px;
		}

		p {
			a {
				@extend .btn-primary;
				border-color: $highlight;
			}
		}

		.accommodation-properties {
			margin-top: 30px;

			&__icon {
				color: $highlight;

				i {
					font-weight: 700;
				}
			}

			&__label {
				color: $highlight;
			}
		}
	}

	.info-extra {
		@include media-breakpoint-down(sm) {
			padding: 0 !important;
		}

		.gallery {
			&-item {
				margin: 30px 0 0 0;
				padding: 0 15px;
			}
		}
	}
}

.content.contact {
	.info {
		max-width: 730px;
		margin: auto;
		text-align: center;

		.container-holder {
			h3 {
				font-size: $h1-font-size;
			}

			p {
				font-size: $font-size-base * 1.125;
				margin-bottom: 90px;
			}
		}
	}

	.container-default {
		.container-holder {
			h3 {
				color: $primary;
				text-transform: uppercase;
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.column.one {
				padding: 40px 15px 70px;

				.form {
					form {
						.form-group {
							.form-control-label {
								font-size: $font-size-base / 1.0667;
								font-family: $font-open-sans;
								font-weight: 700;
							}

							.form-control {
								border: 1px solid $base-1;
								border-radius: 3px;
								color: $tertiary;
								font-style: italic;

								&:focus {
									box-shadow: 0px 0px 5px $base-4;
								}
							}
						}
					}

					small {
						font-family: $font-open-sans;
						font-size: $font-size-base_xs;

						a {
							color: $highlight;
							text-decoration: underline;

							&:hover {
								color: $primary;
							}
						}
					}
				}
			}

			.column.two {
				p {
					font-family: $font-open-sans;
					font-style: $font-size-base;
				}
			}
		}
	}
}

.faq-overview {
	.faq-category {
		.faq-title {
			pointer-events: none;

			h2 {
				text-transform: uppercase;
			}

			.faq-icon {
				display: none !important;
			}
		}

		.faq-items {
			display: block !important;

			h3 {
				font-family: "Maven Pro",sans-serif !important;
			}
		}
	}
}
