.mini-sab {
    z-index: 100;
    position: relative;
    padding: 15px 0;
    background: $base-2;
    &::before {
        content: "";
        z-index: 1;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 15px;
        background: url("/images/repeat-grid-brown.svg") repeat-x center top;
    }
    &::after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 15px;
        background: url("/images/repeat-grid-brown.svg") repeat-x center bottom;
    }
    .container {
        position: relative;
        padding: 0 15px;
        @extend .d-flex;
        @extend .justify-content-center;
        &::after {
            content: "";
            background: url("/images/camper.png") no-repeat;
            background-size: contain;
            width: 389px;
            height: 183px;
            position: absolute;
            bottom: -15px;
            right: 0;
            z-index: 11;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            @include media-breakpoint-down(xs) {
                bottom: 100%;
            }
            @include media-breakpoint-down(lg) {
                width: 192px;
                height: 91px;
            }
        }
    }
}
