///start from here
.page_block {
    &.grid,
    & {
        .item {
            margin: 0 0 50px 0;

            @include media-breakpoint-up(sm) {
                margin: 0 0 70px 0;
            }
            @include media-breakpoint-up(xl) {
                @include make-col(4);
            }
            .card {
                box-shadow: 2px 2px 10px rgba($black, 0.4);
                border: none;
                border-radius: 6px;

                &-body {
                    padding: 15px 15px 30px;
                }
                &-caption {
                    display: flex;
                    flex-direction: column;

                    .card-title-link {
                        order: 2;
                        margin-bottom: $spacer;
                        margin-top: $spacer * 0.5;

                        &:hover {
                            .card-title {
                                color: $tertiary;
                            }
                        }

                        .card-title {
                            font-family: $font-sue-ellen;
                            font-size: $h3-font-size;
                            color: $primary;
                            text-transform: uppercase;
                        }
                    }
                    .card-subtitle {
                        order: 1;
                        font-family: $font-maven-pro;
                        font-size: $font-size-base / 1.0667;
                        color: $secondary;
                        font-weight: 800;
                    }
                }
                &-description {
                    background: transparent;
                    min-height: 100%;
                    max-height: 100%;
                    order: 3;

                    &::after {
                        content: none;
                    }

                    &-content {
                        line-height: 28px;
                        min-height: 70px;
                    }
                    .list-icons {
                        .list-item {
                            padding: 0 0 5px 30px;

                            .icon {
                                color: $highlight;

                                i {
                                    font-weight: 600;
                                }
                            }
                            .label {
                                font-size: $font-size-base_xs;
                                color: $highlight;
                            }
                        }
                    }
                }
                .card-buttons {
                    z-index: 1;
                    margin: 24px 0 0 0;
                }
            }
        }
    }
}
.highlight.highlight_item {
    z-index: 3;
    position: absolute;
    bottom: -140px;
    left: 5%;
    width: 350px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .item {
        max-width: 100%;

        .card {
            &-image {
                padding: 10px 0 0;
                width: 150px;
                background: transparent;
                margin: auto;
            }
            &-body {
                padding: 15px 15px 25px;
                text-align: center;

                .card-caption {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .card-title-link {
                        margin-bottom: 8px;

                        .card-title {
                            text-align: center;
                        }
                    }
                }
                .card-description {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &-content {
                        line-height: 1.5;
                        min-height: auto;
                    }
                }
                .card-buttons {
                    margin: 15px 0 0 0;
                }
            }
        }
    }
}
