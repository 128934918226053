.accordion-list {
  margin-top: 2rem;
  margin-bottom: 3rem;

  &-item {
    border-top: 1px solid $primary;

    &:last-child {
      border-bottom: 1px solid $primary;
    }

    &-header {
      h2 {
        margin-bottom: 0;
      }

      button {
        padding-left: 0;
        padding-right: 0;
        border: unset;
        position: relative;
        font-weight: 700;
        font-size: 18px;
        text-transform: unset;
        letter-spacing: normal;
        text-decoration: none;
        box-shadow: none;
        background-color: transparent;
        color: $primary;

        &:active,
        &:focus {
          box-shadow: none;
          text-decoration: none;
        }

        &:hover {
          color: inherit;
          text-decoration: none;
        }

        &::after {
          content: '\e833';
          font-family: 'fontello';
          position: absolute;
          right: 0;
        }

        &[aria-expanded="true"] {
          &::after {
            content: '\e83c';
          }
        }
      }
    }

    &-content {
      display: none;

      ul:not(.custom-list) {
        padding-left: 1rem;
      }
    }
  }
}
