@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';
@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

html { font-size: 16px };
body.camper {
	@import '~framework/assets/scss/Dev/base';
	@import './2_layout/base';
	@import './2_layout/eyecatcher';
	@import './2_layout/header';
	@import './2_layout/mini-sab';
	@import './2_layout/footer';
	@import './2_layout/section';

	@import './3_component/button';
	@import './3_component/card';
}
