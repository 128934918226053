.footer {
	position: relative;

	.footer {
		&-top {
			text-align: center;
			background-color: $white;
			padding: 35px 30px 100px;

			@include media-breakpoint-up(sm) {
				padding: 35px 30px 50px;
			}

			.footer_logolink {
				display: flex;
				justify-content: center;

				.list {
					justify-content: center;
					align-items: center;

					.list-item {
						margin: 0 25px 30px 0;

						&:nth-of-type(3),
						&:last-of-type {
							@media screen and (max-width: 320px) {
								margin: 0 25px 30px 0 !important;
							}
							@include media-breakpoint-down(sm) {
								margin: 0 0 30px 0;
							}
						}

						.link {
							img {
								width: 77px;
							}
						}
					}
				}
			}
		}

		&-details {
			position: relative;
			text-align: center;
			background-color: $tertiary;
			color: $white;
			padding: 20px 0;

			@include media-breakpoint-down(md) {
				padding: 20px 25px 200px;
			}

			&::before {
				content: "";
				background: url("/images/footer-Path695@2x.png") no-repeat;
				background-size: cover;
				width: 100%;
				height: 55px;
				position: absolute;
				top: -35px;
				left: 0;
				z-index: 1;
				@include media-breakpoint-down(md) {
					background-size: cover;
				}
			}

			&::after {
				content: "";
				background: url("/images/footer-Path696@2x.png") no-repeat;
				background-size: contain;
				width: 100%;
				height: 66px;
				position: absolute;
				bottom: -35px;
				left: 0;
				z-index: 1;
				@include media-breakpoint-down(md) {
					background-size: cover;
				}
			}

			.footer_text {
				p {
					@include media-breakpoint-up(xl) {
						width: 30%;
						margin: 0 auto;
					}
				}

				a {
					color: $secondary;
					font-family: $font-maven-pro;
					font-size: 18px;
					font-weight: 700;
				}

				p {
					margin-bottom: 0;
				}

				h2 {
					font-size: $h1-font-size;
					color: $white;
					text-transform: uppercase;
				}
			}

			.logo {
				margin: 30px 0;
			}

			.contact-block {
				p {
					&:first-of-type {
						a {
							padding-left: 25px;

							&::before {
								position: absolute;
								left: 0;
								content: "\F095";
								font-family: $font-awesome;
								color: $secondary;
							}
						}
					}

					&:nth-of-type(2) {
						margin-bottom: 10px;

						a {
							padding-left: 25px;

							&::before {
								position: absolute;
								left: 0;
								content: "\F0E0";
								font-family: $font-awesome;
								color: $secondary;
							}
						}
					}

					a {
						position: relative;

						&:hover {
							color: $white;
						}
					}
				}
			}

			.vocabulary_term_list {
				display: flex;
				justify-content: center;
				margin-top: 40px;

				a {
					color: $white;
					font-size: 0;

					&:hover {
						text-decoration: none;

						i {
							opacity: 0.7;
						}
					}

					i {
						font-size: 30px;
						width: 48px;
						height: 48px;
						border-radius: 50%;
						border: $border-width solid $white;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		&-socket {
			background-color: $white;
			text-align: center;
			margin: 0 50px 0;
			padding-bottom: 0;

			.container-holder {
				.navbar {
					flex-direction: column;
					padding-top: 50px;
					padding-bottom: 0;
					@include media-breakpoint-down(md) {
						padding-top: 120px;
					}

					.footer_link {
						margin: 30px 0;

						.list {
							&-item {
								margin: 0 30px 0 0;
							}
						}

						a {
							color: $highlight;

							&:hover,
							&:active {
								color: $primary;
							}
						}
					}

					.vocabulary_term_list {
						&.js-scrolltop {
							margin-bottom: 0;

							a {
								font-size: 0;
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100px;
								height: 60px;
								overflow: hidden;
								position: relative;
								margin-top: 10px;

								&::before {
									content: "\f106";
									font-size: 36px;
									font-weight: 800;
									font-family: $font-awesome;
									color: $white;
									z-index: 5;
									position: absolute;
									bottom: -5px;
								}

								&::after {
									content: "";
									position: absolute;
									bottom: 0;
									left: 0;
									width: 100px;
									height: 50px;
									background-color: $secondary;
									border-top-left-radius: 60px;
									border-top-right-radius: 60px;
									border-bottom-left-radius: 0;
									border-bottom-right-radius: 0;
								}
							}
						}
					}
				}
			}

			@include media-breakpoint-down(md) {
				position: relative;
			}

			&::after {
				content: "";
				background: url("/images/Epke_squirrel@2x.png") no-repeat;
				background-size: contain;
				width: 291px;
				height: 540px;
				position: absolute;
				bottom: 150px;
				right: 20vw;
				z-index: 10;
				@media(max-width: 1499px) {
					right: 0;
				}
				@include media-breakpoint-down(md) {
					width: 12rem;
					height: 22rem;
					bottom: 100%;
					left: 50%;
					right: auto;
					transform: translate(-50%, 40%);
				}
				@media screen and (max-width: 320px) {
					height: 25rem;
				}
			}
		}
	}
}
