.mini-sab {
    z-index: 100;
    position: relative;
    padding: 0;
    background: $primary;
    &::before {
        content: "";
        z-index: 1;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 15px;
        background: url("/images/repeat-grid-orange.svg") repeat-x center top;
    }
    &::after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 15px;
        background: url("/images/repeat-grid-orange.svg") repeat-x center bottom;
    }
    .container {
        position: relative;
        padding: 0 15px;
        @extend .d-flex;
        @extend .justify-content-center;
    }
    .container-holder {
        z-index: 100;
        position: relative;
        margin-top: -45px;
        margin-bottom: 5px;
        padding: 10px 15px 15px 15px;
        background: $white;
        border-radius: 4px;
        box-shadow: 0 3px 10px rgba($black, 0.15);
        &::after {
            content: "";
            z-index: 11;
            position: absolute;
            width: 120px;
            height: 222px;
            right: 0;
            bottom: 100%;
            margin-bottom: -40px;
            background: url("/images/Epke_squirrel.png") no-repeat;
            background-size: contain;
            @include media-breakpoint-up(sm) {
                width: 194px;
                height: 360px;
            }
            @include media-breakpoint-up(lg) {
                right: -55px;
            }
            @include media-breakpoint-up(xl) {
                right: -80px;
                width: 232px;
                height: 430px;
                margin-bottom: -60px;
            }
		}
    }
    .heading {
        color: $primary;
        font-weight: 700;
    }
}
