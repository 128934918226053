/* todo: improve menu setup + responsive */
.header-socket .container-two-columns .container-holder {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;

	.column {
		flex: unset;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;

		&.one {
			max-width: 300px;
		}

		&.two {
			max-width: none;

			.navbar-nav,
			.site_switcher {
				width: 50px;
			}
		}
	}
}
h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 2.25 !important;
	}
}
h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.375;
	}
}